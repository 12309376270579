.add-search-term-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.03);
  border-radius: 0 0 8px 8px;
  padding: 8px 0;
}
.image-library-modal {
  .image-list-container {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 28px;
    min-height: 346px;
    max-height: 346px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    padding-left: 3.5rem;
    padding-top: 2rem;
    .image-success-list-container,
    .image-failed-list-container {
      display: flex;
      flex-wrap: wrap;
      gap: 28px;
      padding: 0.5rem;
    }
    .image {
      width: 162px;
      height: 171px;
      margin-bottom: 72px;
      position: relative;
      .checkbox {
        position: absolute;
        right: 0;
        top: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 68px;
        height: 68px;
      }
      .name {
        margin-top: 4px;
      }
      .resolution {
        margin-top: 6px;
      }
    }
  }
  .image-resolution-message {
    display: flex;
    gap: 16px;
    padding-bottom: 18px;
    // align-items: center;
  }
}
.video-library-modal {
  .video-list-container {
    display: flex;
    justify-content: space-around;
    min-height: 346px;
    max-height: 346px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 1rem;
    flex-wrap: wrap;
    .video-list {
      display: flex;
      gap: 8px;
      .name,
      .link {
        width: 179px;
        word-wrap: break-word;
      }
      .video {
        width: 162px;
        height: 171px;
        margin-bottom: 28px;
        position: relative;
        .checkbox {
          position: absolute;
          right: 0;
          top: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 68px;
          height: 68px;
        }
        .name {
          margin-top: 4px;
        }
        .resolution {
          margin-top: 6px;
        }
      }
    }
    .video-details {
      .link {
        display: flex;
        flex-direction: column;
        gap: 70px;
        margin-top: 12px;
      }
      .remove-video {
        width: 64px;
        height: 17px;
        background: rgba(232, 75, 71, 0.09);
        border-radius: 4px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 2px;
      }
    }
  }
}
.image-action-container {
  width: 100%;
  display: flex;
  .add-search-term-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.03);
    border-radius: 0 0 8px 8px;
    padding: 8px 0;
  }
}
.asset-group-tab-list {
  display: flex;
  background-color: #f2f2f2;
  .asset-group-tab {
    height: 35px;
    width: 161px;
    background: #ffffff;
    border-radius: 14.4826px 14.4826px 0px 0px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.02);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #969696;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    // border: 1.45px solid #0869fb;
    img {
      position: absolute;
      right: 15px;
      width: 9px;
      height: 9px;
      cursor: pointer;
    }
  }
}
.pmax-strategy-creation-container {
  padding: 16px 16px 20px;

  .pmax-strategy-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .pmax-field-value {
      color: #878787;
    }
  }
  .pmax-search-term-and-detailed-view {
    display: flex;
    gap: 22px;
  }
  .pmax-existing-new-segment-view {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    .pmax-existing-segment {
      // border: 1.92886px solid #e6f6ff;
      // border-radius: 7.71545px;
      padding: 14px 16px;
      display: flex;
      gap: 12px;
      align-items: center;
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
      .MuiFormControlLabel-label {
        font-size: 13.502px;
        line-height: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        color: #333333;
      }
    }
    .pmax-new-segment {
      border: 1.92886px solid #e6f6ff;
      border-radius: 7.71545px;
      .new-segment-radio {
        .MuiFormControlLabel-label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 13.502px;
          line-height: 19px;
          color: #333333;
        }
      }
      .interests-purchase-radio {
        .MuiFormControlLabel-label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          color: #333333;
        }
      }
      .interests-purchase-radio,
      .search-for-terms-radio {
        .MuiFormControlLabel-label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          color: #333333;
        }
      }
      .google-search-term-list {
        min-height: 184px;
        max-height: 184px;
        overflow-y: scroll;
        padding: 12px 13px 0 10px;
      }
      .google-search-term {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
        border-radius: 8px;
        padding: 8px 15px;
        margin-bottom: 19px;
      }
    }
  }
  .p-max-detailed-view-container {
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    .pmax-detailed-view {
      border: 2px solid #e6f6ff;
      border-radius: 8px;
      padding-top: 7px;
      // padding: 7px 15px 0 15px;
      .pmax-navigation-controls {
        display: flex;
        margin-bottom: 16px;
        // padding: 0px 8px 0 8px;
        justify-content: center;
        gap: 2px;
        .pmax-control {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 13.5px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.5);
          // opacity: 0.5;
          padding: 8px 7.5px;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          gap: 3.5px;
        }
      }
      .pmax-theme-search {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 2px solid #e6f6ff;
        padding-left: 20px;
        .search-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          .search-button {
            width: 116px;
            height: 40px;
            background: rgba(8, 105, 251, 0.07);
            border-radius: 8px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #0869fb;
            text-transform: capitalize;
          }
        }
        .MuiOutlinedInput-root {
          border-radius: 8px;
        }
      }
      .pmax-theme-list {
        padding-left: 25px;
        min-height: 260px;
        max-height: 260px;
        overflow-y: scroll;
        margin-bottom: 15px;
      }
      .headline-container {
        padding-left: 16px;
        padding-right: 16px;
        min-height: 358px;
        max-height: 358px;
        overflow-y: scroll;
        margin-top: 24px;
        margin-bottom: 16px;
        .headline {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-bottom: 12;
          gap: 16px;
          margin-bottom: 16px;
          .headline-text {
            background: #ffffff;
            border: 1px solid #f0f0f0;
            box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
            border-radius: 5px;
            width: 98%;
            padding-left: 15px;

            padding-top: 4px;
            padding-bottom: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 31px;
            min-height: 36px;
          }
        }
      }
      .description-container {
        padding-left: 16px;
        padding-right: 16px;
        min-height: 358px;
        max-height: 358px;
        overflow-y: scroll;
        margin-top: 24px;
        margin-bottom: 16px;
        .description {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-bottom: 12;
          gap: 16px;
          margin-bottom: 16px;
          .description-text {
            background: #ffffff;
            border: 1px solid #f0f0f0;
            box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
            border-radius: 5px;
            width: 98%;
            height: 68px;
            padding-left: 15px;
            padding-top: 4px;
            padding-bottom: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 31px;
          }
        }
      }
      .image-view-container {
        padding: 6px 27px 18px 27px;
        position: relative;
        z-index: 1;
        .image-list-container {
          display: flex;
          flex-wrap: wrap;
          gap: 28px;
          min-height: 324px;
          max-height: 324px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 0.5rem;
          .image {
            width: 162px;
            height: 171px;
            margin-bottom: 112px;
            position: relative;

            .delete-container {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff3f3;
              border-radius: 50%;
              position: absolute;
              top: -8px;
              right: -8px;
              z-index: 100;

              img {
                width: 14px;
                height: 14px;
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .play-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 68px;
              height: 68px;
            }
            .name {
              margin-top: 4px;
            }
            .resolution {
              margin-top: 6px;
            }
          }
        }
        .image-resolution-message {
          display: flex;
          gap: 16px;
          padding-bottom: 18px;
          // align-items: center;
        }
      }
      .video-view-container {
        padding: 6px 27px 18px 27px;
        position: relative;
        z-index: 1;
        .yb-search-pmax-container {
          margin-bottom: 20px;
          .text-link-area {
            display: flex;
            align-items: center;
            gap: 16px;
            .MuiTextField-root {
              .MuiOutlinedInput-root {
                height: 41.22px;
                // border: 0;
                border: 0.964432px solid #dddddd;
                border-radius: 7.71545px;
              }
            }
          }
        }
        .video-list-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          min-height: 272px;
          max-height: 272px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 0.5rem;
          .video-list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .video {
              width: 162px;
              height: 171px;
              margin-bottom: 38px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 68px;
                height: 68px;
              }
              .name {
                margin-top: 4px;
              }
              .resolution {
                margin-top: 6px;
              }
            }
          }
          .video-details {
            .link {
              display: flex;
              flex-direction: column;
              gap: 2.75rem;
              margin-top: 12px;
            }
            .remove-video {
              width: 64px;
              height: 17px;
              background: rgba(232, 75, 71, 0.09);
              border-radius: 4px;
              text-transform: capitalize;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-left: 2px;
            }
          }
        }
      }
    }
  }
}
