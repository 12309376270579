.paper_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 98vh;
}
.layout {
  padding: 27px 41px 25px;
}
.read_container-hdm {
  padding-right: 18px;
  width: 100%;
}
.read-access {
  background: #fff;
}
.readaccess_paper {
  box-shadow: none !important;
}
.access_heading {
  font-family: sans-serif;
  font-weight: 600;
  color: #4a3aff;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  line-height: 40px;
}

.footer_row_read {
  border-top: 1px solid #dbdee2;
  background: #fff;
  padding: 18px 25px 19px;
  position: fixed;
  bottom: 0;
  left: 307px;
  right: 0;
}
.footer_content {
  display: flex;
  justify-content: flex-end;
}
.paragraph {
  color: #6f6c90;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  text-align: center;
}
.paragraph a {
  color: #6f6c90;
}
.read_title {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 25px;
  line-height: 62px;
  text-align: center;
  color: #170f49;
  margin: 0;
}
.lock_icon {
  width: 41px;
  height: 41px;
  background: url(../../../assets/icons/readaccess/unlock_icon.svg) no-repeat 0
    0;
  background-size: contain;
  display: block;
  margin: auto;
}
.top_read_section {
  margin-bottom: 36px;
}
.inner_paper_row {
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 18px 0;
  margin-top: 21px;
}
.form_input {
  margin: 28px auto 18px;
  width: 100%;
  max-width: 330px;
}
.access_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.google_icon {
  background: url(../../../assets/icons/social/google1.png) no-repeat 0 0;
  width: 48px;
  height: 48px;
  background-size: contain;
  display: block;
}
.facebook_icon {
  background: url(../../../assets/icons/social/facebook1.png) no-repeat 0 0;
  width: 48px;
  height: 48px;
  background-size: contain;
  display: block;
}
.drop_arrow {
  background: url(../../../assets/icons/common/droparrow.svg) no-repeat 0 0;
  width: 10px;
  height: 10px;
  background-size: contain;
}
.accrodian_access > div {
  box-shadow: none;
  display: flex;
  flex-direction: column-reverse;
}
.accrodian_access .accordian_title {
  min-height: auto;
  box-shadow: none;
}
.accordian_title .typography_accordian {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #0869fb;
  margin-right: 5px;
}
.accordian_title .typography_accordian:before {
  content: 'Know How';
  position: relative;
}
.accordian_title div.Mui-expanded .typography_accordian::before {
  content: 'Collapse';
}
.accordian_title div.Mui-expanded .drop_arrow {
  transform: rotate(-180deg);
}

.accrodian_access .accordian_title.Mui-expanded {
  min-height: auto;
}
.accordian_title > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.accordian_title > div.Mui-expanded {
  margin: 0;
}
.tag_wrapper {
  text-align: center;
}
.accordian_content {
  margin-top: 30px;
}
.title_tag {
  background: #f8f8f8;
  border-radius: 7px;
  padding: 10px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.accordian_list {
  margin-top: 23px;
}
.accordian_list li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 20px;
}
.accordian_list li a {
  color: #000000;
}
.know_more_img {
  margin-top: 13px;
  text-align: center;
}

.setting_icon {
  background: url(../../../assets/icons/readaccess/setting_icon.svg) no-repeat 0
    0;
  background-size: contain;
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
}
.plus_icon {
  background: url(../../../assets/icons/readaccess/plus_icon.svg) no-repeat 0 0;
  background-size: contain;
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
}
.status_popup_layout {
  text-align: center;
}
.status_popup_layout p {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #6f6c90;
  margin: 10px 0 0;
}
.status_popup_layout h2 {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #000000;
}
.action_popup {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.action_popup button,
.action_popup a {
  margin: 0 32px;
}
.close_button {
  background: url(../../../assets/icons/readaccess/close.svg) no-repeat 0 0;
  background-size: contain;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 19px;
}

.grid_home_page_hdm {
  display: flex;
  justify-content: space-between;
}
.grid_account_column_hdm {
  background: #fff;
  border-radius: 8px;
  padding: 0 !important;
  height: 364px;
  width: 100%;
  overflow: auto;
}
.grid_colum_hdm {
  width: calc(50% - 8px);
  padding: 0;
  background: #ffffff;
  margin: 16px;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.social_list_home_hdm {
  display: flex;
  align-items: center;
  padding: 20px;
}
.social_name_title_hdm {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #170f49;
  margin: 0;
  margin-left: 8px;
}

.asset_title {
  position: relative;
}
.center {
  text-align: center;
  margin: auto;
  width: 70%;
  height: auto;
  padding-top: 15%;
}
.img-submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.submit-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: #000000;
}
.submit-desc {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #6f6c90;
  margin-bottom: 15px;
}
