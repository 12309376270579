@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.case_list strong {
  font-family: 'Montserrat';
  font-size: 12px;
}

.case_list p {
  font-family: 'Montserrat';
  font-size: 12px;
  margin: 0;
}

.case_list li {
  margin-bottom: 10px;
}

.case_list li::marker {
  color: #f1484c;
}

.strategy {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}

.strategy li {
  margin-bottom: 10px;
}

.strategy li::marker {
  color: #f1484c;
}

.design {
  height: 816px;
  margin-bottom: 4rem;
}

.design a {
  color: '#58baf9';
  font-weight: bold;
  text-decoration: none;
}

.land_list p {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}

.land_list li {
  margin-bottom: 10px;
}

.land_list li::marker {
  color: #f1484c;
}

.pop_up li::marker {
  color: #f1484c;
}

.pop_up p {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}

.pop_up li {
  margin-bottom: 20px;
}

.project_timeline {
  height: 816px;
  margin-bottom: 2rem;
}

.client_expectation {
  height: 816px;
  position: relative;
  margin-bottom: 2rem;
}

.referal {
  height: 816px;
  position: relative;
  margin-bottom: 2rem;
}

.thankyou_pg {
  height: 816px;
  position: relative;
  background-color: #171742;
  padding-top: 6rem;
  padding-left: 6rem;
  padding-bottom: 6rem;
  margin-bottom: 2rem;
  overflow: hidden;
}

.customized_report {
  height: 760px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.paid_search {
  height: 760px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 71px;
  padding-right: 74px;
}

.land_list2 p {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}

.land_list2 li {
  margin-bottom: 10px;
}

.land_list2 li::marker {
  color: #f1484c;
}

.land_list2 {
  padding-left: 14px;
}

.pop_up2 li::marker {
  color: #f1484c;
}

.pop_up2 p {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}

.pop_up2 li {
  margin-bottom: 20px;
}

.pop_up2 {
  padding-left: 14px;
}

.pop_up3 li::marker {
  color: #f1484c;
}

.pop_up3 p {
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 1.4;
  font-weight: 500;
  margin: 0;
}

.pop_up3 {
  padding-left: 14px;
}

.project_scope {
  /* height: 790px; */
  position: relative;
  padding-top: 4.87rem;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  padding-bottom: 4.5rem;
}

.terms_condition {
  /* height: 760px; */
  position: relative;
  margin-bottom: 5rem;
}

/* .css-1ygcj2i-MuiTableCell-root{
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #FFFFFF;
    padding: 0;
    padding-left: 106px;
}
.css-1ex1afd-MuiTableCell-root{
    font-size: 12px;
    font-family: 'Montserrat';
    padding: 0;
    font-weight: 500;
    padding-bottom: 1px;
    padding-top: 10px;
    border-bottom: none;
} */