.googlebox{
    display: flex;
    flex-direction: column;
    width: 98%;
    padding-left: 1.5rem;
}
.available span{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #999999;
}
.googlebox h2{
    font-size: 20px;
    font-family: 'Inter';
    line-height: 28px;
    color: #242731;
    font-weight: 600;
}
.line{
    border-bottom: 1px solid #F0F0F0; padding: 18px 16px;
    width: 50%;
}
.assignee span{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #999999;
}

.ai{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-top: 5px;
}

.ai span{
    color: #333333;
}
.ai p{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #999999;
}
.ft{
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.options{
    padding-top: 2rem;
    padding-left: 2rem;
}

.footer_row{border-top:1px solid #DBDEE2; padding: 18px 25px}
.footer_down{
    
   display: flex;
   justify-content: flex-end;
   gap: 2rem;
}
.download{ 
    background: none;
    border: 1px solid #0869FB;
    color: #0869FB;
    font-family: 'Inter';
    font-weight: 600;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 10px;
    
    
}
.download:hover{
    cursor: pointer;
}