.price-card-container {
  width: 336px;
  height: 470px;
  background: #ffffff;
  border: 0.7699px solid #eff0f7;
  box-shadow: 0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08);
  border-radius: 10.5055px;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  overflow-y: hidden !important;
  position: relative;
}
.pricing {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.price {
  margin: 2rem 0;
}
.price p {
  color: #6f6c90;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13.8582px;
  line-height: 15px;
}
.price p span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26.9465px;
  color: #170f49;
}
.features ul {
  list-style: none;
  padding-left: 0;
}
.features ul li {
  margin-left: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13.8582px;
  color: #170f49;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.price-rate-referral-input .MuiInput-underline:before {
  border-bottom: none !important;
}
.price-rate-referral-input .MuiInput-underline:after {
  border-bottom: none !important;
}
.optional-add-ons {
  margin: 1.5rem 0;
  cursor: pointer;
}
