/* .right_layout {
  overflow-y: auto;
} */
.right_main_section {
  display: block;
}
.campaign-container {
  padding: 1rem 1rem 0 1rem;
  height: calc(100vh - 121px);
  position: relative;
  margin-bottom: 30px;
  overflow-y: auto;
}
.campaign-header-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.campaign-title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  /* line-height: 19px; */
}
.fblookalike-section-container {
  display: flex;
  gap: 30px;
}
