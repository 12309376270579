.hero12{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(../../../assets/icons/onBoardHDMAM.svg) no-repeat; 
  margin: 0rem 3.5rem;
  background-size:100% 100%;
}

.phase{
    display: block;
    align-items: center;
    justify-content: center;
    height: 556px;
    max-width: 100%;
    /* border-radius: 2rem; */
    background: url(../../../assets/icons/onBoardPhaseHDMAM.svg) no-repeat; 
    margin: 0rem 3.5rem;
    background-size:100% 100%;
}

.agenda_head{
    height: 150.97px;
    width: 100%;
    background: url(../../../assets/images/Onboarding/agenda.svg) ;
    margin-bottom: 40.47px;
}

.list{
    display: flex;
    align-items: center;
    border-radius: 200px;
    height: 72px;
    width: 326px;
    padding: 10px ;
    background-color: #F5F9FF;
    margin-left: 24px;
    margin-bottom: 66px;
}

.circle{
    width: 56px;
    margin-right: 16px;
        line-height: 56px;
        border-radius: 50%;
        text-align: center;
        font-size: 20px;
        background-color: #0869FB;
        border: 1px solid transparent ;
}

.asset-head{
    height: 150.97px;
    width: 100%;
    background: url(../../../assets/images/Onboarding/asset.svg) ;
    margin-bottom: 40.47px;
}

.connections{
    /* height: 50vh; */
    /* padding-left: 32px;
    padding-right: 32px; */
}

.main_grid{
    display: flex; justify-content: space-between;
}
/* .grid_acc_col{
    background: #fff; border-radius: 8px;  padding: 0!important; min-height:364px; width: 100%;
}
.grid_col{
    width: calc(50% - 8px); padding: 0;background: #FFFFFF;margin: 16px;
    box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px; margin-bottom: 16px; display: flex; align-items: center; width: 100%; position: relative; justify-content: space-between;
}

.social_list{
    display: flex; align-items: center;padding: 20px;
} */
.grid_col{
    width: calc(50% - 8px); padding: 0;background: #FFFFFF;margin: 16px;
    box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px; margin-bottom: 16px; display: flex; align-items: center;  position: relative; justify-content: space-between;
}
.grid_acc_col{
    background: #fff; border-radius: 8px;  padding: 0!important; min-height:364px; width: 100%;
}
.social_list{
    display: flex; align-items: center;padding: 20px;
}
.css-1ex1afd-MuiTableCell-root{
    color: #333333;
}
.strategy{
    margin-bottom: 63px;
}